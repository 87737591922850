import { useMutation, useQuery } from '@apollo/client';
import { type AdminTatItemLeadTimeOverridesEditor_QueryQuery, graphql } from '@orthly/graphql-inline-react';
import { useListLabOrgs } from '@orthly/graphql-react';
import { MUITable } from '@orthly/mui-table';
import { TimeUtils } from '@orthly/runtime-utils';
import { QuickForm, RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { Grid, Icon, IconButton } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const AdminTatItemLeadTimeOverridesEditor_Query = graphql(`
    query AdminTatItemLeadTimeOverridesEditor_Query {
        tatListItemLeadTimeOverrides {
            id
            organizationId
            disabled
            catalogTime
            overLimitCatalogTime
            leadTimeByType {
                buffer
                design
                fabrication
                orderReview
                shipping
                waxupReview
            }
            overLimitLeadTimeByType {
                buffer
                design
                fabrication
                orderReview
                shipping
                waxupReview
            }
            unitCountLimit
        }
    }
`);
type TatItemLeadTimeOverride = AdminTatItemLeadTimeOverridesEditor_QueryQuery['tatListItemLeadTimeOverrides'][number];
type TatItemLeadTimeOverrideWithOrganizationName = TatItemLeadTimeOverride & { organization_name: string };

const AdminTatItemLeadTimeOverrideModalUpsert_Mutation = graphql(`
    mutation AdminTatItemLeadTimeOverrideModalUpsert_Mutation(
        $id: String!
        $organizationId: String!
        $disabled: Boolean!
        $leadTimeByType: TatLeadTimeByTypeArgsDto!
        $unitCountLimit: Int
        $overLimitLeadTimeByType: TatLeadTimeByTypeArgsDto
        $catalogTime: Int
        $overLimitCatalogTime: Int
    ) {
        tatUpsertItemLeadTimeOverride(
            id: $id
            organizationId: $organizationId
            disabled: $disabled
            leadTimeByType: $leadTimeByType
            overLimitLeadTimeByType: $overLimitLeadTimeByType
            unitCountLimit: $unitCountLimit
            catalogTime: $catalogTime
            overLimitCatalogTime: $overLimitCatalogTime
        ) {
            id
        }
    }
`);

interface TatItemLeadTimeOverrideModalProps {
    leadTime: TatItemLeadTimeOverrideWithOrganizationName;
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    refetch: () => Promise<any>;
}

interface TatItemLeadTimeOverrideModalFormVars {
    disabled: boolean;
    catalogDays: number | null;
    leadTimeByType: {
        design: number;
        fabrication: number;
        shipping: number;
        buffer: number;
    };
    overLimitLeadTimes?: {
        unitCountLimit: number;
        catalogDays: number | null;
        design: number;
        fabrication: number;
        shipping: number;
        buffer: number;
    } | null;
}

// Shared field props for the numeric inputs on the form
const fieldPropsNumber = {
    // max of 50 days for any stage should be a safe assumption
    inputProps: { min: 0, max: 50 },
    // prevent wheel event from updating value as that can cause it to change massively when user tries to scroll
    onWheel: (e: React.WheelEvent<HTMLDivElement>) => {
        // see MUI issue discussion: https://github.com/mui/material-ui/issues/7960#issuecomment-2270719632
        if (e.target instanceof HTMLInputElement) {
            e.target.blur();
        }
    },
};

const TatItemLeadTimeOverrideModal: React.VFC<TatItemLeadTimeOverrideModalProps> = ({ leadTime, refetch }) => {
    const [open, setOpen] = React.useState<boolean>(false);

    const upsertMtn = useMutation(AdminTatItemLeadTimeOverrideModalUpsert_Mutation);
    const { submit, submitting } = useRootActionCommand(upsertMtn, {
        successMessage: 'Updated item lead time override.',
        onSuccess: async () => {
            await refetch();
            setOpen(false);
        },
    });

    return (
        <RootActionDialog
            title={`Edit Item Lead Time Override`}
            open={open}
            setOpen={setOpen}
            subtitle={`${leadTime.id} - ${leadTime.organization_name}`}
            subtitleTextProps={{ variant: 'body1' }}
            loading={submitting}
            showCloseButton
            content={
                <QuickForm<TatItemLeadTimeOverrideModalFormVars>
                    dirtySubmitOnly
                    fields={{
                        disabled: {
                            type: 'boolean',
                            label: 'Disabled',
                            optional: false,
                        },
                        catalogDays: {
                            type: 'number',
                            label: 'Catalog Days',
                            optional: true,
                            fieldProps: fieldPropsNumber,
                        },
                        leadTimeByType: {
                            type: 'nested',
                            optional: false,
                            label: 'Lead Times',
                            fields: {
                                design: {
                                    type: 'number',
                                    label: 'Design Lead Time (days)',
                                    optional: false,
                                    fieldProps: fieldPropsNumber,
                                },
                                fabrication: {
                                    type: 'number',
                                    label: 'Fabrication Lead Time (Days)',
                                    optional: false,
                                    fieldProps: fieldPropsNumber,
                                },
                                shipping: {
                                    type: 'number',
                                    label: 'Shipping Lead Time (Days)',
                                    optional: false,
                                    fieldProps: fieldPropsNumber,
                                },
                                buffer: {
                                    type: 'number',
                                    label: 'Buffer Lead Time (Days)',
                                    optional: false,
                                    fieldProps: fieldPropsNumber,
                                },
                            },
                        },
                        overLimitLeadTimes: {
                            type: 'nested',
                            optional: true,
                            label: 'Over Limit Lead Times',
                            fields: {
                                unitCountLimit: {
                                    type: 'number',
                                    label: 'Unit Count Limit',
                                    optional: false,
                                    fieldProps: fieldPropsNumber,
                                },
                                catalogDays: {
                                    type: 'number',
                                    label: 'Over Limit Catalog Days',
                                    optional: true,
                                    fieldProps: fieldPropsNumber,
                                },
                                design: {
                                    type: 'number',
                                    label: 'Over Limit Design Lead Time (days)',
                                    optional: false,
                                    fieldProps: fieldPropsNumber,
                                },
                                fabrication: {
                                    type: 'number',
                                    label: 'Over Limit Fabrication Lead Time (Days)',
                                    optional: false,
                                    fieldProps: fieldPropsNumber,
                                },
                                shipping: {
                                    type: 'number',
                                    label: 'Over Limit Shipping Lead Time (Days)',
                                    optional: false,
                                    fieldProps: fieldPropsNumber,
                                },
                                buffer: {
                                    type: 'number',
                                    label: 'Over Limit Buffer Lead Time (Days)',
                                    optional: false,
                                    fieldProps: fieldPropsNumber,
                                },
                            },
                        },
                    }}
                    resetOnInitialValueChange={true}
                    initialValues={{
                        disabled: leadTime.disabled,
                        catalogDays: leadTime.catalogTime
                            ? TimeUtils.convertHoursToDays(leadTime.catalogTime, 'trunc')
                            : null,
                        leadTimeByType: {
                            buffer: TimeUtils.convertHoursToDays(leadTime.leadTimeByType.buffer, 'trunc'),
                            design: TimeUtils.convertHoursToDays(leadTime.leadTimeByType.design, 'trunc'),
                            fabrication: TimeUtils.convertHoursToDays(leadTime.leadTimeByType.fabrication, 'trunc'),
                            shipping: TimeUtils.convertHoursToDays(leadTime.leadTimeByType.shipping, 'trunc'),
                        },
                        overLimitLeadTimes:
                            leadTime.overLimitLeadTimeByType && leadTime.unitCountLimit
                                ? {
                                      unitCountLimit: leadTime.unitCountLimit,
                                      catalogDays: leadTime.overLimitCatalogTime
                                          ? TimeUtils.convertHoursToDays(leadTime.overLimitCatalogTime, 'trunc')
                                          : null,
                                      buffer: TimeUtils.convertHoursToDays(
                                          leadTime.overLimitLeadTimeByType.buffer,
                                          'trunc',
                                      ),
                                      design: TimeUtils.convertHoursToDays(
                                          leadTime.overLimitLeadTimeByType.design,
                                          'trunc',
                                      ),
                                      fabrication: TimeUtils.convertHoursToDays(
                                          leadTime.overLimitLeadTimeByType.fabrication,
                                          'trunc',
                                      ),
                                      shipping: TimeUtils.convertHoursToDays(
                                          leadTime.overLimitLeadTimeByType.shipping,
                                          'trunc',
                                      ),
                                  }
                                : null,
                    }}
                    disabled={submitting}
                    onSubmit={async vars => {
                        await submit({
                            id: leadTime.id,
                            organizationId: leadTime.organizationId,
                            disabled: vars.disabled,
                            catalogTime: vars.catalogDays ? TimeUtils.convertDaysToHours(vars.catalogDays) : null,
                            leadTimeByType: {
                                buffer: TimeUtils.convertDaysToHours(vars.leadTimeByType.buffer),
                                design: TimeUtils.convertDaysToHours(vars.leadTimeByType.design),
                                fabrication: TimeUtils.convertDaysToHours(vars.leadTimeByType.fabrication),
                                shipping: TimeUtils.convertDaysToHours(vars.leadTimeByType.shipping),
                                orderReview: 0,
                                waxupReview: 0,
                            },
                            overLimitLeadTimeByType: vars.overLimitLeadTimes
                                ? {
                                      buffer: TimeUtils.convertDaysToHours(vars.overLimitLeadTimes.buffer),
                                      design: TimeUtils.convertDaysToHours(vars.overLimitLeadTimes.design),
                                      fabrication: TimeUtils.convertDaysToHours(vars.overLimitLeadTimes.fabrication),
                                      shipping: TimeUtils.convertDaysToHours(vars.overLimitLeadTimes.shipping),
                                      orderReview: 0,
                                      waxupReview: 0,
                                  }
                                : null,
                            unitCountLimit: vars.overLimitLeadTimes?.unitCountLimit ?? null,
                            overLimitCatalogTime: vars.overLimitLeadTimes?.catalogDays
                                ? TimeUtils.convertDaysToHours(vars.overLimitLeadTimes.catalogDays)
                                : null,
                        });
                    }}
                />
            }
            CustomButton={() => (
                <IconButton onClick={() => setOpen(true)}>
                    <Icon icon={'SettingsIcon'} />
                </IconButton>
            )}
        />
    );
};

export const TatItemLeadTimeOverridesEditor: React.VFC = () => {
    const { data: { listOrganizations: labs = [] } = {}, loading: labsLoading } = useListLabOrgs({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });
    const { data: itemsData, loading: loadingItems, refetch } = useQuery(AdminTatItemLeadTimeOverridesEditor_Query);
    const items = React.useMemo(() => {
        const tats = _.sortBy(itemsData?.tatListItemLeadTimeOverrides ?? [], r => r.id).map(r => ({
            ...r,
            organization_name: labs?.find(({ id }) => id === r.organizationId)?.name ?? r.organizationId,
        }));
        return tats;
    }, [itemsData, labs]);

    return (
        <Grid container>
            <MUITable<TatItemLeadTimeOverrideWithOrganizationName>
                title={'Item Lead Time Overrides'}
                loading={loadingItems || labsLoading}
                data={items}
                displayOptions={{
                    fixedSearch: true,
                    elevation: 0,
                    viewColumns: true,
                    filter: true,
                    sort: true,
                }}
                actions={{
                    global: [{ icon: 'refresh', position: 'toolbar', onClick: refetch }],
                }}
                rowOptions={{ rowHover: true }}
                eventHooks={{ onRowClick: (row, actions) => actions.toggleDetailPanel(row) }}
                columns={[
                    { name: 'ID', render: r => r.id },
                    { name: 'Organization', render: r => r.organization_name },
                    { name: 'Enabled', render: r => (!r.disabled ? '✅' : '🚫') },
                    {
                        name: 'Catalog Days',
                        render: r => (r.catalogTime ? TimeUtils.convertHoursToDays(r.catalogTime, 'trunc') : 'Not Set'),
                        filterOptions: {
                            type: 'dropdown',
                        },
                    },
                    {
                        name: 'Design Lead Time (Days)',
                        title: 'Design Lead Time (Days)',
                        render: r => TimeUtils.convertHoursToDays(r.leadTimeByType.design, 'trunc'),
                    },
                    {
                        name: 'Fabrication Lead Time (Days)',
                        title: 'Fabrication Lead Time (Days)',
                        render: r => TimeUtils.convertHoursToDays(r.leadTimeByType.fabrication, 'trunc'),
                    },
                    {
                        name: 'Shipping Lead Time (Days)',
                        title: 'Shipping Lead Time (Days)',
                        render: r => TimeUtils.convertHoursToDays(r.leadTimeByType.shipping, 'trunc'),
                    },
                    {
                        name: 'Buffer Lead Time (Days)',
                        title: 'Buffer Lead Time (Days)',
                        render: r => TimeUtils.convertHoursToDays(r.leadTimeByType.buffer, 'trunc'),
                    },
                    { name: 'Unit Count Limit', render: r => r.unitCountLimit },
                    {
                        name: `Actions`,
                        render: row => <TatItemLeadTimeOverrideModal leadTime={row} refetch={refetch} key={row.id} />,
                    },
                ]}
            />
        </Grid>
    );
};
