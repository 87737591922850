import { RouterTabs } from '../../../components/RouterTabs';
import { TatAddonLeadTimesEditor } from './TatAddonLeadTimesEditor.graphql';
import { TatItemLeadTimeOverridesEditor } from './TatItemLeadTimeOverridesEditor.graphql';
import { TatItemLeadTimesEditor } from './TatItemLeadTimesEditor.graphql';
import React from 'react';

export const TatRoot: React.VFC = () => {
    return (
        <RouterTabs
            defaultPath={'items'}
            items={[
                { path: 'items', label: 'Items', Component: TatItemLeadTimesEditor },
                { path: 'overrides', label: 'Item Overrides', Component: TatItemLeadTimeOverridesEditor },
                { path: 'addons', label: 'Addons', Component: TatAddonLeadTimesEditor },
            ]}
        />
    );
};
